import {Link} from "react-router-dom";

import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import {Mailto} from "../../utils";

function Footer({content}) {
    const {brand, menus, copyright} = content;

    return (
        <MKBox component="footer">
            <Container>
                <Grid container spacing={3}>
                    <Grid xs={12} md={3} sx={{ml: 0, mr: "auto", mb: 2}}
                          display="flex" justifyContent="start" alignItems="center">
                        <MKBox>
                            <Link to={brand.route}>
                                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="16rem" mb={0}/>
                            </Link>
                        </MKBox>
                        {/*
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
            */}

                    </Grid>

                    <Grid xs={6} md={2} sx={{mb: 2}}>
                        <MKTypography
                            display="block"
                            variant="button"
                            fontWeight="bold"
                            textTransform="capitalize"
                            mb={1}
                        >
                            Kontakt
                        </MKTypography>

                        <MKBox component="ul" p={0} m={0} sx={{listStyle: "none"}}>
                            <MKBox component="li" p={0} m={0} lineHeight={1.00}>
                                <MKTypography variant="button" fontWeight="regular">
                                    Vileala GmbH<br/>
                                    Scherzingerstr. 33<br/>
                                    8595 Altnau
                                </MKTypography>
                            </MKBox>

                            <MKBox component="li" p={0} pt={0.5} m={0} lineHeight={1.25}>
                                <MKTypography variant="button" fontWeight="regular">
                                    <MKBox display="flex" gap={0.5} alignItems="center">
                                        <Icon>email</Icon>
                                        <Mailto email="info@vileala.ch">
                                            <MKTypography variant="button" fontWeight="regular">
                                                info@vileala.ch
                                            </MKTypography>
                                        </Mailto>
                                    </MKBox>
                                    <MKBox display="flex" gap={0.5} alignItems="center">
                                        <Icon>phone</Icon>
                                        <span>+41 71 508 01 69</span>
                                    </MKBox>
                                </MKTypography>
                            </MKBox>
                        </MKBox>
                    </Grid>

                    {menus.map(({name: title, items}) => (
                        <Grid key={title} xs={6} md={2} sx={{mb: 2}}>
                            <MKTypography
                                display="block"
                                variant="button"
                                fontWeight="bold"
                                textTransform="capitalize"
                                mb={1}
                            >
                                {title}
                            </MKTypography>
                            <MKBox component="ul" p={0} m={0} sx={{listStyle: "none"}}>
                                {items.map(({name, route, href}) => (
                                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                                        {href ? (
                                            <MKTypography
                                                component="a"
                                                href={href}
                                                target="_blank"
                                                rel="noreferrer"
                                                variant="button"
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {name}
                                            </MKTypography>
                                        ) : (
                                            <MKTypography
                                                component={Link}
                                                to={route}
                                                variant="button"
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {name}
                                            </MKTypography>
                                        )}
                                    </MKBox>
                                ))}
                            </MKBox>
                        </Grid>
                    ))}
                    <Grid xs={12} sx={{textAlign: "center", my: 1}}>
                        {copyright}
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

// Typechecking props for the DefaultFooter
Footer.propTypes = {
    content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default Footer;
