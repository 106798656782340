import { useEffect, useRef } from "react";

import Rellax from "rellax";

import Typed from "typed.js";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

/*
import Information from "pages/Company/AboutUs/sections/Information";
import Team from "pages/Company/AboutUs/sections/Team";
import Featuring from "pages/Company/AboutUs/sections/Featuring";
import Newsletter from "pages/Company/AboutUs/sections/Newsletter";
*/

import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/bg-landing.jpg";
import Intro from "./sections/Intro";

function Welcome() {
    const headerRef = useRef(null);
    const typedWord1Ref = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {


    const typedWord1 = new Typed(typedWord1Ref.current, {
      strings: ["smart", "innovativ", "effizient", "ökologisch", "frisch", "nachhaltig", "regional", "ökonomisch"],
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 300,
      startDelay: 600,
      loop: true,
    });


    return () => {
        typedWord1.destroy();
    }
  }, []);

  return (
    <>
      <Navbar
        brand={footerRoutes.brand}
        routes={routes}
        /*action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "default",
        }}*/
        transparent
        light
      />
      <MKBox
        ref={headerRef}
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
          <MKTypography
                  variant="h2"
                  color="white"
                  mb={2}
                  sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                      },
                  })}
              >
              Edelpilze <span ref={typedWord1Ref} />
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
        <Card
            sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                overflow: "hidden",
                minHeight: "20vh"
            }}
        >
            <Intro />
        </Card>
      <MKBox pt={3} px={1} mt={3}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Welcome;
