import pxToRem from "assets/theme/functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
    },
  },
};
