import MKTypography from "components/MKTypography";

import logoVileala from "assets/images/vileala-logo-full-dark.png";

const date = new Date().getFullYear();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  brand: {
    name: "Vileala",
    image: logoVileala,
    route: "/",
  },
  /*
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
   */
  menus: [
      /*
    {
      name: "informationen",
      items: [
        { name: "über uns", href: "https://www.creative-tim.com/presentation" },
      ],
    },*/
    {
      name: "rechtliches",
      items: [
        {
          name: "impressum",
          route: "/impressum",
        },
        {
          name: "datenschutz",
          route: "/datenschutz",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; {date} -{" "}
      <MKTypography
        component="a"
        href="https://vileala.ch"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Vileala GmbH
      </MKTypography>
    </MKTypography>
  ),
};
