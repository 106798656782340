import {lazy, Suspense, useEffect} from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import Welcome from "pages/Welcome";

import routes from "routes";

const Imprint = lazy(() => import('./pages/Imprint'));
const DataProtection = lazy(() => import('./pages/DataProtection'));


export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Welcome />} />
              <Route path="/impressum" element={<Imprint />} />
              <Route path="/datenschutz" element={<DataProtection />} />
              <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
    </ThemeProvider>
  );
}
