import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import InfoCard from "components/cards/InfoCard";

function Intro() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h3" my={1}>
            Jung, dynamisch und leidenschaftlich
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Als junges und dynamisches Startup sind wir leidenschaftlich in der Planung, dem Bau und dem Betrieb
            modernster Zuchtanlagen für Edelpilze. Mit einem starken Fokus auf Nachhaltigkeit, Regionalität und
            Innovation setzen wir modernste Technologien ein, um stets die höchste Qualität und Frische unserer Produkte zu gewährleisten.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <InfoCard
              icon="sensors"
              color="primary"
              title="Moderne Zuchtanlagen"
              description="Wir bauen und betreiben innovative Zuchtanlagen für Edelpilze.
              Durch den Einsatz modernster Technologie und voll digitalisierter Prozesse gewährleisten wir eine
              präzise Steuerung und Überwachung des gesamten Zuchtablaufs. Dies ermöglicht es uns, die besten
              Bedingungen für das Wachstum unserer Edelpilze zu schaffen und gleichzeitig die Effizienz unserer
              Produktionsanlagen zu maximieren."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <InfoCard
              icon="local_florist"
              color="primary"
              title="Nachhaltigkeit und Regionalität"
              description="Nachhaltigkeit und Regionalität stehen im Mittelpunkt unserer Unternehmenskultur.
              Wir verwenden ausschliesslich nachhaltige Ressourcen und achten darauf, unsere Umwelt so wenig wie
              möglich zu belasten. Zudem beziehen wir alle benötigten Materialien und Rohstoffe aus der Region,
              um die lokale Wirtschaft zu unterstützen und Transportwege kurz zu halten. Dies trägt dazu bei, unseren
              ökologischen Fussabdruck zu minimieren und eine nachhaltige Pilzproduktion sicherzustellen."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <InfoCard
              icon="verified"
              color="primary"
              title="Qualität, Frische und Innovation"
              description="Qualität und Frische sind für uns von höchster Bedeutung.
              Durch unsere fortschrittlichen Anbautechniken und die kontinuierliche Überwachung aller
              Produktionsschritte stellen wir sicher, dass unsere Edelpilze stets von bester Qualität und
              aussergewöhnlicher Frische sind. Unser Engagement für Innovation treibt uns an, ständig neue
              Methoden und Technologien zu entwickeln, um die Effizienz und Nachhaltigkeit unserer Produktionsprozesse
              weiter zu verbessern und unseren Kunden die besten Produkte auf dem Markt zu bieten."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Intro;
