import typography from "assets/theme/base/typography";

import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl,
    },
  },
};
